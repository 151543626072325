import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Cookie extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate() {
        this.setTitle();
    }

    setTitle() {
        const { t } = this.props;
        document.title = t("PAGE.COOKIE.TITLE");
    }

    render() {
        const { t } = this.props;

        return (
            <div id="Cookie">
                <div className="col-md-12">
                    <Trans i18nKey='PAGE.GLOBAL.COOKIESDISCLAIMER'>{t('PAGE.GLOBAL.COOKIESDISCLAIMER')}</Trans>
                    <a href={t('PAGE.GLOBAL.COOKIESLINK')} target="_blank">
                        {t('PAGE.GLOBAL.COOKIESLINK')}
                        <FontAwesomeIcon className="icon" icon="external-link-alt" />
                    </a>
                </div>
            </div >
        );
    }
}

export default withTranslation()(Cookie);
