import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Generic component to show an alert as a modal on a page
class AlertModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: true
        }
    }

    toggle = () => {
        if (this.state.modal) {
            this.props.closecb();
        }

        this.setState({ modal: !this.state.modal });
    }

    render() {
        return (
            <div>
                <Modal id="alert-modal" isOpen={this.state.modal} toggle={this.toggle}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        <label>{this.props.text}</label>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

export default AlertModal;