import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

class HomeAlert extends Component {

    constructor(props) {
        super(props);

        this.state = {
            announcementVisible: true
        }
    }

    render() {
        const currentTime = new Date();
        const { announcement } = this.props;

        let annVisible = localStorage.getItem(announcement.name) == null;

        let expiryDate = new Date(announcement.expiryDate)

        if (expiryDate > currentTime && annVisible) {
            return (
                < Alert color={announcement.severity} isOpen={this.state.announcementVisible} toggle={this.alertSeen.bind(this)} >
                    <td dangerouslySetInnerHTML={{ __html: announcement.text }} />
                </Alert >
            )
        }
        return (null);
    }

    alertSeen() {
        const { announcement } = this.props;
        localStorage.setItem('' + announcement.name, "1");
        this.setState({ announcementVisible: false });
    }
}

export default withTranslation()(HomeAlert);