import React, { Component } from 'react';
import { Route, Redirect } from 'react-router';

const PrivateRoute = ({ component: Component, auth: isAuth, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuth === true
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/',
                state: { loginAlert: true }
            }} />
    )}/>
)

export default PrivateRoute;