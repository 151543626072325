import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthenticationService from '../../services/AuthenticationService';
import { UserContext } from '../../context/UserContext';
import ReactGA from "react-ga4";
import AuthenticationModal from '../authentication/AuthenticationModal';

const api = new AuthenticationService();

// Modal popup to register or login
class AuthenticationButton extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);

        this.state = { modal: false }

        this.toggle = this.toggle.bind(this);
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    logout() {        
        api.logout(this.context.setValue);
        this.setState({ username: '', password: '' });

        ReactGA.event({
            category: "User",
            action: "User Logged out",
            nonInteraction: false,
        });
    }

    getLoginLogoutButton() {
        const { t } = this.props;

        // if context value is empty or null, then we show the login button
        if (this.context.value) {
            return (<button className="btn btn-primary btn-wide" onClick={this.logout.bind(this)}>{t('PAGE.GLOBAL.LOGOUT')}</button>);
        } else {
            return (<button className="btn btn-primary btn-wide" onClick={this.toggle}>{t('PAGE.GLOBAL.LOGIN')}</button>);
        }

        return <></>
    }


    render() {
        const { t } = this.props;
        return (
            <div>
                {this.getLoginLogoutButton()}
                {this.state.modal && <AuthenticationModal toggle={ this.toggle } />}
            </div>
        )
    }
}

export default withTranslation()( AuthenticationButton );