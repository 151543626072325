import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'bootstrap/dist/css/bootstrap.css';
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './i18n';
import './css/main.scss';

const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>,
    rootElement);