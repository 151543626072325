import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import AuthenticationService from '../../services/AuthenticationService';
import { UserContext } from '../../context/UserContext';
import { FavoriteContext } from '../../context/FavoriteContext';
import Login from './Login';
import Register from './Register';
import ReactGA from "react-ga4";

const api = new AuthenticationService();

// Modal popup to register or login
class AuthenticationModal extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            text: props.text ?? '',
            modal: true,
            errorState: false,
            resMsg: '',
            emptyModal: false,
            register: false,
        }

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        const { toggle: toggleFunc } = this.props;

        if (toggleFunc !== undefined) {
            toggleFunc();
        }
        else {
            this.setState(prevState => ({ modal: !prevState.modal }));
        }

    }

    logout() {        
        api.logout(this.context.setValue);
        this.setState({ username: '', password: '' });

        ReactGA.event({
            category: "User",
            action: "User Logged out",
            nonInteraction: false,
        });
    }

    getLoginLogoutButton() {
        // if context value is empty or null, then we show the login button
        if (this.context.value) {
            return (<button className="btn btn-primary btn-wide" onClick={this.logout.bind(this)}>{t('PAGE.GLOBAL.LOGOUT')}</button>);
        } else {
            return (<button className="btn btn-primary btn-wide" onClick={this.toggle}>{t('PAGE.GLOBAL.LOGIN')}</button>);
        }
    }

    setResponse(msg, state) {
        // remove register and login
        // components on register success
        let registerSuccess = (msg === 'PAGE.GLOBAL.REGISTERSUCCESS');

        this.setState({ resMsg: msg, errorState: state, emptyModal: registerSuccess });
    }

    showMessageAlert() {
        const { t } = this.props;

        if (this.state.resMsg) {
            let alertColor = this.state.errorState
                ? 'danger'
                : 'success';
            

            return (
                <Alert color={alertColor}>
                    {t(this.state.resMsg)}
                </Alert>
            );
        }

        return null;
    }

    resetAlert() {
        this.setState({ resMsg: null, errorState: false, emptyModal: false });
    }

    toggleRegiser() {
        this.setState(prevState => ({ register: !prevState.register }));
    }

    render() {
        const { t } = this.props;
        return (
            <Modal
                isOpen={this.state.modal}
                toggle={this.toggle}
                className={this.props.className}
                size="modal-dialog"
                onClosed={this.resetAlert.bind(this)}>
                <ModalHeader toggle={this.toggle}></ModalHeader>
                <ModalBody>
                    {!this.state.emptyModal && (
                        <>
                            {!!this.props.text && <div className="alert alert-primary" role="alert">{this.props.text}</div>}
                            {this.state.register && (
                                <Register callback={this.setResponse.bind(this)} />
                            )}
                            {!this.state.register && (
                                < FavoriteContext.Consumer >
                                    {({ favValue, setFavValue }) => (
                                        <Login
                                            onSuccess={this.toggle}
                                            callback={this.setResponse.bind(this)}
                                            favoritecb={setFavValue} />
                                    )}
                                </FavoriteContext.Consumer>
                            )}
                            <Row>
                                <Col>
                                    <span id="register"
                                        className="float-left w-75 mt-2 userdialogtext-clickable"
                                        onClick={this.toggleRegiser.bind(this)}> {this.state.register ? t('PAGE.GLOBAL.LOGIN') : t('PAGE.LOGIN.REGISTER')}
                                    </span>
                                </Col>
                            </Row>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    {this.showMessageAlert()}
                </ModalFooter>
            </Modal>
        )
    }
}

export default withTranslation()(AuthenticationModal);