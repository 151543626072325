import React, { Component } from 'react';

class PasswordStrength extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentScore: 10
        }
    }

    componentDidMount() {
        this.calculateScore();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (prevProps.pass !== this.props.pass) {
            this.calculateScore();
        }
    }

    calculateScore() {
        const score = this.measureStrength(this.props.pass);

        // if score is higher than 30, then this is
        // a valid password
        if (score > 30 && !this.props.valid) {
            this.props.validation(true);
        } else if (score <= 30 && this.props.valid) {
            this.props.validation(false);
        }

        // update current score
        this.setState({ currentScore: score });
    }

    measureStrength(p) {
        var _force = 0;
        const _regex = /[$-/:-?{-~!"^_`[\]]/g;

        var _lowerLetters = /[a-z]+/.test(p);
        var _upperLetters = /[A-Z]+/.test(p);
        var _numbers = /[0-9]+/.test(p);
        var _symbols = _regex.test(p);

        var _flags = [_lowerLetters, _upperLetters, _numbers, _symbols];

        var _passedMatches = 0;
        var i;
        for (i = 0; i < _flags.length; i++) {
            if (_flags[i]) {
                _passedMatches++;
            }
        }

        _force += 2 * p.length + ((p.length >= 10) ? 1 : 0);
        _force += _passedMatches * 10;

        // penality (short password)
        _force = (p.length <= 6) ? Math.min(_force, 10) : _force;

        // penality (poor variety of characters)
        _force = (_passedMatches === 1) ? Math.min(_force, 10) : _force;
        _force = (_passedMatches === 2) ? Math.min(_force, 20) : _force;
        _force = (_passedMatches === 3) ? Math.min(_force, 40) : _force;

        return _force;
    }

    getPasswordStrengthIndex() {
        let score = this.state.currentScore;

        var idx = 0;
        if (score <= 10) {
            idx = 0;
        } else if (score <= 20) {
            idx = 1;
        } else if (score <= 30) {
            idx = 2;
        } else if (score <= 40) {
            idx = 3;
        } else {
            idx = 4;
        }

        return idx + 1;
    }

    getClassOfItem(itemIndex, strengthIndex) {
        return (itemIndex <= strengthIndex)
            ? "passwordstrength-" + strengthIndex
            : "passwordstrength-0";
    }

    render() {
        const currentStrengthIndex = this.getPasswordStrengthIndex();

        return (
            <>
                <li className={"point " + this.getClassOfItem(1, currentStrengthIndex)}></li>
                <li className={"point " + this.getClassOfItem(2, currentStrengthIndex)}></li>
                <li className={"point " + this.getClassOfItem(3, currentStrengthIndex)}></li>
                <li className={"point " + this.getClassOfItem(4, currentStrengthIndex)}></li>
                <li className={"point " + this.getClassOfItem(5, currentStrengthIndex)}></li>
            </>
        );
    }
}

export default PasswordStrength;