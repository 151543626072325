import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import AccountService from '../../services/AccountService';
import { Link } from 'react-router-dom';
import PasswordStrength from '../../core/authentication/PasswordStrength';

const api = new AccountService();
const qs = require('querystringify');

// Confirm email of user
class ResetPassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            hasError: false,
            errorText: '',
            password: '',
            passwordConfirm: '',
            passwordValid: false,
            passwordConfirmValid: false,
            formValid: false,
            updateClicked: false
        }

        // get all query parameters
        let query = this.props.location.search;
        this.username = '';
        this.token = '';

        const queryparams = qs.parse(query);

        if (queryparams.username) {
            this.username = queryparams.username;
        }

        if (queryparams.token) {
            this.token = queryparams.token;
        }
    }

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate() {
        this.setTitle();
    }

    setTitle() {
        const { t } = this.props;
        document.title = t('PAGE.NEWPASSWORD.TITLE');
    }

    // get message from response if there are any errors
    setErrorMessage(text) {
        this.setState({ errorText: text });
    }

    // check whether both passwords are valid
    validateForm = () => {
        const { passwordValid, passwordConfirmValid } = this.state;

        let newFormState = passwordValid && passwordConfirmValid;

        if (this.state.formValid != newFormState) {
            this.setState({
                formValid: newFormState
            });
        }
    }

    // update password
    handlePasswordInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const name = target.name;

        const { passwordConfirm } = this.state;
        var passwordConfirmStillValid = false;
        if (passwordConfirm == value) {
            passwordConfirmStillValid = true;
        }

        this.setState({ [name]: value, passwordConfirmValid: passwordConfirmStillValid }, this.validateForm);
    }

    // password validity is written from password strength
    validatePassword(valid) {
        this.setState({ passwordValid: valid });
    }

    // validate confirmation pass
    validatePasswordConfirmInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;

        var valid = false;

        if (this.state.password === value) {
            valid = true;
        }

        this.setState(
            {
                passwordConfirm: value,
                passwordConfirmValid: valid
            },
            this.validateForm);
    }

    // green or red input menu
    controlExtraCss(value, valueValid) {
        var className = '';

        if (value) {
            if (valueValid) {
                className = "input-correct";
            } else {
                className = "input-error";
            }
        }

        return className;
    }

    // show an alert when update is clicked
    getAlertText() {
        const { t } = this.props;
        if (this.state.hasError) {
            return (
                <Alert color="danger">
                    {this.state.errorText ? this.state.errorText : "Error"}
                </Alert>);
        } else {
            return (
                <Alert color="success">
                    {t('PAGE.NEWPASSWORD.PASSWORDUPDATED')}
                </Alert>);
        }
    }

    // command to reset password
    reset = async (e) => {
        e.preventDefault();

        this.setState({ isLoading: true });

        const resetHasError = await api.resetPassword(
            this.username,
            this.state.password,
            this.state.passwordConfirm,
            this.token,
            this.setErrorMessage.bind(this)
        );

        this.setState({ isLoading: false, updateClicked: true, hasError: resetHasError });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <h1>{t('PAGE.NEWPASSWORD.HEADER') + '[' + this.username + ']'}</h1>
                <div className="row col-md-5 with-margin">
                    <form name="resetPass" onSubmit={this.reset}>
                        <fieldset>
                            <div className="form-group">
                                <input
                                    type="password"
                                    name="password"
                                    className={"form-control " +
                                        this.controlExtraCss(this.state.password, this.state.passwordValid)}
                                    placeholder={t('PAGE.LOGIN.PASSWORD')}
                                    tabIndex="1"
                                    onChange={this.handlePasswordInput}
                                    disabled={this.state.updateClicked}
                                    required />
                                {this.state.password
                                    ? <PasswordStrength
                                        pass={this.state.password}
                                        validation={this.validatePassword.bind(this)}
                                        valid={this.state.passwordValid} />
                                    : null}
                                <div className="password-hint">{t('PAGE.LOGIN.PASSWORDREQUIREMENT')}</div>
                            </div>
                            <div className="form-group">
                                <input
                                    type="password"
                                    className={"form-control " +
                                        this.controlExtraCss(this.state.passwordConfirm,
                                            this.state.passwordConfirmValid)}
                                    name="passwordConfirm"
                                    placeholder={t('PAGE.LOGIN.CONFIRMPASSWORD')}
                                    tabIndex="2"
                                    onChange={this.validatePasswordConfirmInput}
                                    disabled={this.state.updateClicked}
                                    required />
                            </div>

                            {this.state.updateClicked
                                ? <>
                                    <div>
                                        {this.getAlertText()}
                                    </div>
                                    <div>
                                        <Link to={t('routes:home')}>
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-wide">
                                                {t('PAGE.LOGIN.REDIRECTHOME')}
                                            </button>
                                        </Link>
                                    </div>
                                </>
                                : <>
                                    <button
                                        disabled={!this.state.formValid || this.state.isLoading}
                                        type="submit"
                                        className="btn btn-primary btn-wide float-left">
                                        <span>
                                            {t('PAGE.GLOBAL.UPDATE')}
                                        </span>
                                    </button>
                                    <Link to={t('routes:home')}>
                                        <button
                                            type="button"
                                            className="btn btn-secondary btn-wide"
                                            disabled={this.state.isLoading}>
                                            {t('PAGE.GLOBAL.CANCEL')}
                                        </button>
                                    </Link>
                                </>}
                        </fieldset>
                    </form>
                </div>
            </>
        );
    }
}

export default withTranslation()(ResetPassword);