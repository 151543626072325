import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AlertModal from '../generic/AlertModal';
import { UserContext } from '../../context/UserContext';

// Alert to show if user session has ended
class SessionEnd extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        // check whether session has expired every 60 seconds
        this.timerID = setInterval(() => this.checkSessionExpired(), (1000 * 60));
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    checkSessionExpired() {
        let sessionInfo = this.context.value;

        if (sessionInfo) {
            const jsonInfo = JSON.parse(sessionInfo);
            const { expires } = jsonInfo;

            let currentTime = new Date().getTime();
            let expiryTime = Date.parse(expires)

            // if bearer token has expired, then log user out
            if (currentTime >= expiryTime) {
                this.setState({ showModal: true });
            }
        }
    }

    closeModal() {
        this.setState({ showModal: false });
    }

    render() {
        const { t } = this.props;

        if (this.state.showModal) {
            return <AlertModal text={t('PAGE.LOGIN.RELOGIN')} closecb={this.closeModal.bind(this)}/>;
        } else {
            return null;
        }
    }
}

export default withTranslation()(SessionEnd);