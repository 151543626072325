import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import AuthenticationService from '../../services/AuthenticationService';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';

const api = new AuthenticationService();

class DeleteUserModal extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            modal: true,
            resMsg: '',
            errorState: false
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    setResponse(msg, state) {
        this.setState({ resMsg: msg, errorState: state });
    }

    async deleteUser() {
        const userInfoJson = JSON.parse(this.context.value);
        const { userName, token } = userInfoJson;

        const deleteSuccess = await api.deleteuser(userName, token, this.setResponse.bind(this));

        if (deleteSuccess) {
            this.props.callback();

            // user has been deleted,
            // log user out
            await api.logout(this.context.setValue);
        }
    }

    showAlert(text, error) {
        var colour = 'info';

        if (error) {
            colour = 'danger';
        } else {
            colour = 'success';
        }

        return (<Alert color={colour}>{text}</Alert>);
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                    onClosed={this.props.callback}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label>{t('PAGE.DELETEUSER.DELETEWARNING')}</label>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.resMsg
                                ? this.showAlert(t(this.state.resMsg), this.state.errorState)
                                : <Button className="btn btn-primary btn-wide pull-left" onClick={this.deleteUser.bind(this)}>
                                    {t('PAGE.DELETEUSER.CONFIRMDELETE')}
                                </Button>
                        }
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(DeleteUserModal);