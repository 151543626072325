import React, { Component } from 'react';
import Api from './Api';

class FavoriteService extends Component {
    constructor(props) {
        super(props);
    }

    // get info about product groups
    async getFavoritesInfo(token) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        var userHasProductGroups = false;
        var userHasRawMaterials = false;

        // get productgroups
        const pgUrl = '/api/userproductgroup/hasfavorites/';

        await Api.get(pgUrl, header).then(res => {
            userHasProductGroups = res.data;
        }).catch(err => {
            console.error('encountered an error:', err);
        });

        // get rawmaterials
        const materialUrl = '/api/userrawmaterial/hasfavorites/';

        await Api.get(materialUrl, header).then(res => {
            userHasRawMaterials = res.data;
        }).catch(err => {
            console.error('encountered an error:', err);
        });

        let userHasFav = (userHasProductGroups || userHasRawMaterials);

        if (userHasFav) {
            localStorage.setItem('fav', '1');
        } else {
            localStorage.setItem('fav', '0');
        }

        return userHasFav;
    }

    // check whether user has this raw material in his favorites
    async getUserHasMaterialAsFavorite(token, id) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        const materialUrl = '/api/userrawmaterial/hasuserrawmaterial/' + id + '/';
        let userHasRawMaterials;

        await Api.get(materialUrl, header).then(res => {
            userHasRawMaterials = res.data;
        }).catch(err => {
            console.error('encountered an error:', err);
        });

        return userHasRawMaterials;
    }

    // update the favorite material of a user by adding or deleting
    async updateUserFavoriteMaterial(token, id, add) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        let materialUrl;

        // add material to user favorites
        if (add) {
            materialUrl = '/api/userrawmaterial/create/' + id + '/';
        } else {
            // or delete if add is false
            materialUrl = '/api/userrawmaterial/delete/' + id + '/';
        }

        let errorState = false;

        await Api.get(materialUrl, header).then(res => {
            errorState = false;
        }).catch(err => {
            errorState = true;
            console.error('encountered an error:', err);
        });

        return errorState;
    }

    // get all favorite materials of user
    async getMaterialFavoriteInfo(token) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        var errorState = false;
        const urlPost = '/api/userrawmaterial/';
        var data;

        await Api.get(urlPost, header).then(res => {
            data = res.data;
        }).catch(err => {
            console.error('encountered an error:', err);
            errorState = true;
        });

        return errorState ? null : data;
    }

    // get all favorite product groups of user
    async getGroupFavoriteInfo(token) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        var errorState = false;
        const urlPost = '/api/userproductgroup/';
        var data;

        await Api.get(urlPost, header).then(res => {
            data = res.data;
        }).catch(err => {
            console.error('encountered an error:', err);
            errorState = true;
        });

        return errorState ? null : data;
    }

    // update the favorite product group of a user by adding or deleting
    async updateUserFavoriteGroup(token, code, add) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        let groupUrl;

        // add group to user favorites
        if (add) {
            groupUrl = '/api/userproductgroup/create/' + code + '/';
        } else {
            // or delete if add is false
            groupUrl = '/api/userproductgroup/delete/' + code + '/';
        }

        let errorState = false;

        await Api.get(groupUrl, header).then(res => {
            errorState = false;
        }).catch(err => {
            errorState = true;
            console.error('encountered an error:', err);
        });

        return errorState;
    }

    // check whether user has this product group in his favorites
    async getUserHasGroupAsFavorite(token, id) {
        const header = {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        };

        const groupUrl = '/api/userproductgroup/hasuserproductgroup/' + id + '/';
        let userHasProductgroup;

        await Api.get(groupUrl, header).then(res => {
            userHasProductgroup = res.data;
        }).catch(err => {
            console.error('encountered an error:', err);
        });

        return userHasProductgroup;
    }
}

export default FavoriteService;