import React, { Component } from 'react';
import { Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Trans } from 'react-i18next';

import InfoIcon from '../../images/icons/info_icon.PNG';
import CloseInfoImage from '../../images/close_popover.png';

// Generic component for use throughout the application
class InfoPopover extends Component {

    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false
        }
    }

    toggle = () => {
        this.setState({ popoverOpen: !this.state.popoverOpen });
    }

    render() {
        const { content, extraClass, transkey, placement = "right" } = this.props;

        let contentIsArray = Array.isArray(content);

        // save the key of the translated text
        let contentKey = this.props.id;
        if (transkey) {
            contentKey = transkey;
        }

        return (
            <>
                <img
                    className={extraClass ? "infogreysquare " + extraClass : "infogreysquare"}
                    id={this.props.id}
                    alt="info"
                    src={InfoIcon}
                    onClick={this.toggle} />
                <Popover 
                    placement = {this.props.placement}
                    isOpen = {this.state.popoverOpen} 
                    target = {this.props.id} 
                    toggle = {this.toggle} 
                    trigger = "focus"
                >
                    <PopoverHeader className="info-popover-header">
                        <img 
                            src={CloseInfoImage} 
                            alt="close" 
                            onClick={this.toggle} 
                        />
                    </PopoverHeader>
                    <PopoverBody>
                        {contentIsArray
                            ? <div>
                                {
                                    content.map(function (item, i) {
                                        // if this is a html dom object, then just show in the application
                                        if (typeof item === 'object') {
                                            return <div key={i}>{item}</div>;
                                        } 
                                        else {
                                            // check whether an i18nKey is available
                                            if (contentKey) {
                                                if (Array.isArray(contentKey) && contentKey.length > i) {
                                                    
                                                    return (
                                                        <div key={i}>
                                                            <Trans i18nKey={contentKey[i]}>
                                                                {item}
                                                            </Trans>
                                                        </div>);
                                                }
                                            }

                                            // otherwise return default
                                            return (
                                                <div key={i}>
                                                    <Trans i18nKey={null}>
                                                        {item}
                                                    </Trans>
                                                </div>);
                                        }
                                    })
                                }
                            </div>
                            : <Trans i18nKey={contentKey}>
                                {content}
                            </Trans>
                        }
                    </PopoverBody>
                </Popover>
            </>
        );
    }
}

export default InfoPopover;