import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Alert } from 'reactstrap';
import { Redirect } from 'react-router-dom'
import { UserContext } from '../../context/UserContext';

import NextIconOrangeHorizontal from '../../images/icons/next-icon-orange-horizontal.png';
import NextIconOrangeVertical from '../../images/icons/next-icon-orange.PNG';
import SearchIconWhite from '../../images/icons/search-icon-white.PNG';
import LocationIconWhite from '../../images/icons/location-icon-white.PNG';
import AnalysisIconWhite from '../../images/icons/analysis-icon-white.PNG';
import StepsHomeHorizontal from '../../images/steps/steps-home-horizontal.PNG';
import StepsHomeBlue from '../../images/steps/steps-home-blue.png';
import AnnouncementService from '../../services/AnnouncementService';
import HomeAlert from './HomeAlert'
import Cookies from "js-cookie";
import { LanguageCookie, LanguageNlCookieValue } from "../../constants/Constants";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faUsers, faBriefcase } from '@fortawesome/free-solid-svg-icons'

const api = new AnnouncementService();

class Home extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            startHere: false,
            isLoading: true,
            announcementData: null,
            oldLanguageCookie: Cookies.get(LanguageCookie),
        }
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        this.setState({ isLoading: true });

        const language = this.state.oldLanguageCookie ? this.state.oldLanguageCookie : LanguageNlCookieValue;

        const responseData = (await api.getAnnouncements(language));
        this.setState({ announcementData: responseData, isLoading: false });

        const { t } = this.props;
        document.title = t("APPNAME");
    }

    // Show an alert on the page. Usually used for when user
    // is accessing the application without logging in
    showAlert(alertText) {
        return (
            <Alert color="info">
                {alertText}
            </Alert>
        );
    }

    showAnnouncementAlert() {

        if (this.state.announcementData !== null) {
            return this.state.announcementData.map(function (announcementData) {
                return (
                    <HomeAlert announcement={announcementData} />
                )
            })
        }
    }

    // Event for clicked on 'Start here'
    redirectToExplore() {
        this.setState({ startHere: true });
    }

    render() {
        const { t } = this.props;

        if (this.state.startHere) {
            return <Redirect to={t('routes:explore')}/>;
        }
        if (this.state.isLoading) {
            return "loading";
        }

        let loginAlert = false;

        if (this.props.location.state) {
            if (this.props.location.state.loginAlert) {
                loginAlert = this.props.location.state.loginAlert;
            }
        }
        // if there is a value in the user context
        // user is logged in, then hide alert
        if (this.context.value) {
            loginAlert = false;
        }

        return (
            <div id="home">
                <div>
                    {loginAlert ? this.showAlert(t('PAGE.HOME.NOTAUTHENTICATED')) : null}
                    {this.showAnnouncementAlert()}
                </div>
                <div className="col-md-12 page-intro">
                    <div className="row">
                        <p>
                            {t('PAGE.HOME.INTROBODY')}
                        </p>
                  
                        <button onClick={this.redirectToExplore.bind(this)} className="btn btn-primary btn-wide btn-center">{t('PAGE.HOME.STARTBUTTON')}</button>
                        <br/>
                    </div>

                    <div id="purpose" className="row">
                        <div className="col-sm-12 col-md-4">
                            <p><FontAwesomeIcon icon={faBriefcase} size="4x" /></p>
                            <h2>{t('PAGE.HOME.WHY')}</h2>
                            <p>{t('PAGE.HOME.WHYTEXT')}</p>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <p><FontAwesomeIcon icon={faChartBar} size="4x" /></p>
                            <h2>{t('PAGE.HOME.HOW')}</h2>
                            <p>{t('PAGE.HOME.HOWTEXT')}</p>
                        </div>
                        <div className="col-sm-12 col-md-4">
                            <p><FontAwesomeIcon icon={faUsers} size="4x" /></p>
                            <h2>{t('PAGE.HOME.WHO')}</h2>
                            <p>{t('PAGE.HOME.WHOTEXT')}</p>
                        </div>
                    </div>

                    <div className="row">
                        <div id="homepage-content" className="col-md-12 text-center">
                            <div className="row">
                                <div id="homepage-block" className="col-sm-12 col-md-10">
                                    <div className="row">
                                        <div className="col-sm-12 col-md-auto step-block">
                                            <div className="icon">
                                                <img src={SearchIconWhite} />
                                            </div>
                                            <div>
                                                <p>{t('PAGE.HOME.FIRSTSTEP')}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-auto next-icon">
                                            <img src={NextIconOrangeHorizontal} />
                                        </div>
                                        <div className="col-sm-12 next-icon-vertical">
                                            <img src={NextIconOrangeVertical} />
                                        </div>
                                        <div className="col-sm-12 col-md-auto step-block">
                                            <div className="icon">
                                                <img src={LocationIconWhite} />
                                            </div>
                                            <div>
                                                <p>{t('PAGE.HOME.SECONDSTEP')}</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-auto next-icon">
                                            <img src={NextIconOrangeHorizontal} />
                                        </div>
                                        <div className="col-sm-12 next-icon-vertical">
                                            <img src={NextIconOrangeVertical} />
                                        </div>
                                        <div className="col-sm-12 col-md-auto step-block">
                                            <div className="icon">
                                                <img src={AnalysisIconWhite} />
                                            </div>
                                            <div>
                                                <p>{t('PAGE.HOME.THIRDSTEP')}</p>
                                            </div>
                                        </div>
                                        <div id="homepage-steps" className="col-md-12">
                                            <img src={StepsHomeHorizontal} />
                                        </div>
                                        <div id="homepage-steps-vertical" className="col-sm-12">
                                            <div id="homepage-steps-vertical-image" className="row">
                                                <img src={StepsHomeBlue} />
                                            </div>
                                            <div className="row">
                                                <div className="col-4 text-left">
                                                    <h4>{t('PAGE.HOME.EXPLORETITLE')}</h4>
                                                </div>
                                                <div className="col-4">
                                                    <h4>{t('PAGE.HOME.CRITICIZETITLE')}</h4>
                                                </div>
                                                <div className="col-4 text-right">
                                                    <h4>{t('PAGE.HOME.ACTIONTITLE')}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="homepage-buttons-container" className="col-sm-12 col-md-2">
                                    <div className="row">
                                        <div className="col-sm-12 btn-start" onClick={this.redirectToExplore.bind(this)}>
                                            <p>{t('PAGE.HOME.START')}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="step-titles" className="row">
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-3 col-lg-4">
                                            <h3>{t('PAGE.HOME.EXPLORETITLE')}</h3>
                                        </div>
                                        <div className="col-md-6 col-lg-4">
                                            <h3>{t('PAGE.HOME.CRITICIZETITLE')}</h3>
                                        </div>
                                        <div className="col-md-3 col-lg-4">
                                            <h3>{t('PAGE.HOME.ACTIONTITLE')}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default withTranslation()(Home);