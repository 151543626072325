import FavoriteService from '../../services/FavoriteService';
const favApi = new FavoriteService();

/** Check session storage for
 *  current favorite info.
 * */
export function getSessionFavInfo() {
    const fav = localStorage.getItem("fav");

    if (fav) {
        if (fav == 1) {
            return true;
        } else {
            return false;
        }
    }

    return false;
}