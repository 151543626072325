import React, { Component } from 'react';
import { Container, Navbar } from 'reactstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faAt } from '@fortawesome/free-solid-svg-icons';
class Footer extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { t } = this.props;

        const shareLinkedIn = () => {
            const url = `https://www.linkedin.com/shareArticle?mini=true&url=${window.location}`
            window.open(url, "_blank");
        }
        const shareFacebook = () => {
            const url = `https://www.facebook.com/sharer/sharer.php?u=${window.location}`
            window.open(url, "_blank");
        }
        const shareByEmail = () => {

            const subject = 'Grondstoffenscanner.nl';
            const body = window.location;

            const url = `mailto:?subject=${subject}&body=${body}`
            window.open(url, "_blank");
        }

        return (
            <footer className="footer-bottom">
                <div className="flex-grow-1"></div>
                <Navbar className="navbar-bottom border-top box-shadow bg-dark" dark>
                    <Container>
                        <div className="navbar-text pt-1 pb-1 homepage-footer d-sm-flex flex-sm-row flex-fill">
                            <div className="d-sm-flex flex-sm-column">
                                <label>Grondstoffenscanner.nl</label>
                                <div className="d-flex flex-wrap justify-content-between footer-text">
                                    <div className="">
                                        <Link to={t('routes:info')}>{t('PAGE.FOOTER.INFOTITLE')}</Link>
                                    </div>
                                    <div >
                                        <Link to={t('routes:cookie')}>{t('PAGE.FOOTER.COOKIESTITLE')}</Link>
                                    </div>
                                    <div >
                                        <Link to={t('routes:contact')}>{t('PAGE.FOOTER.CONTACTTITLE')}</Link>
                                    </div>
                                    <div >
                                        <Link to={t('routes:privacy')}>{t('PAGE.FOOTER.PRIVACYTITLE')}</Link>
                                    </div>
                                    <div >
                                        <Link to={t('routes:accessibility')}>{t('PAGE.FOOTER.ACCESSIBILITYTITLE')}</Link>
                                    </div>
                                    <div >
                                        <Link to={t('routes:faq')}>{t('PAGE.FOOTER.FAQTITLE')}</Link>
                                    </div>
                                    <div className="footer-share">
                                        {t('PAGE.FOOTER.SHARE')} &nbsp;&nbsp;&nbsp; 
            
                                        <FontAwesomeIcon icon={faLinkedin} size="xl" onClick={ shareLinkedIn } />
                                        <FontAwesomeIcon icon={faFacebook} size="xl" onClick={shareFacebook } />
                                        <FontAwesomeIcon icon={faAt} size="xl" onClick={ shareByEmail }  />
                                    </div>
                                </div>
                                <div className="pt-4 footer-text">
                                    <i>{t('PAGE.FOOTER.DISCLAIMER')}</i>
                                </div>
                            </div>
                            <div className="ml-auto align-self-center">
                                &copy;&nbsp;{(new Date().getFullYear())}&nbsp;DICTU
                            </div>
                        </div>
                    </Container>
                </Navbar>
            </footer>
        );
    }
}

export default withTranslation()(Footer);
