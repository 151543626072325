import Api from "./Api";

class AnnouncementService {
    async getAnnouncements(languageCookie) {
        let errorState = false;
        const urlGet = `/api/announcement/announcements?language=${languageCookie}`;

        let data;

        await Api.get(urlGet).then(res => {
            data = res.data;
        }).catch(err => {
            console.error("AnnouncementService: Fetching announcements caused error:", err);
            errorState = true;
        });

        return errorState ? null : data;
    }
}

export default AnnouncementService;