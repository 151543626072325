import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Disclaimer for the application
class Disclaimer extends Component {

    render() {
        const { t } = this.props;
        const privacyLink = t('PAGE.GLOBAL.PRIVACYLINK');
        const cookieLink = t('PAGE.GLOBAL.COOKIESLINK');

        return (
            <div>
                <Trans i18nKey='PAGE.GLOBAL.DISCLAIMERCONTENT'>{t('PAGE.GLOBAL.DISCLAIMERCONTENT')}</Trans>

                <div>
                    <Trans i18nKey='PAGE.GLOBAL.PRIVACYDISCLAIMER'>{t('PAGE.GLOBAL.PRIVACYDISCLAIMER')}</Trans>
                    <p>
                        <a href={privacyLink} target="_blank">
                            {privacyLink}
                            <FontAwesomeIcon className="icon" icon="external-link-alt" />
                        </a>
                    </p>
                </div>

                <div>
                    <Trans i18nKey='PAGE.GLOBAL.COOKIESDISCLAIMER'>{t('PAGE.GLOBAL.COOKIESDISCLAIMER')}</Trans>
                    <p>
                        <a href={cookieLink} target="_blank">
                            {cookieLink}
                            <FontAwesomeIcon className="icon" icon="external-link-alt" />
                        </a>
                    </p>
                </div>
            </div>
        )
    }
}

export default withTranslation()(Disclaimer);