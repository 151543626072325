import { Component } from "react";
import Api from "./Api";

class FeedbackService extends Component {

    constructor(props) {
        super(props);
    }

    replaceSpaces(text) {
        // encoding text string leads to replacement
        // of strings, so we put that back
        const regex = new RegExp("%20", "g");
        text = text.replace(regex, " ");
        return text;
    }

    // save user feedback
    async saveFeedback(comment, rating, url, username, updateStatus) {
        const jsonPayload = {
            Comment: this.replaceSpaces(encodeURIComponent(comment)),
            Rating: encodeURIComponent(rating),
            URL: encodeURIComponent(url),
            UserId: encodeURIComponent(username)
        };

        const header = {
            headers: {
                'Content-Type': "application/json"
            }
        };

        var errorState = false;
        const urlPost = "/api/feedback/save";

        await Api.post(urlPost, jsonPayload, header).then(res => {
            updateStatus("PAGE.FEEDBACK.FEEDBACKSAVED", errorState);
        }).catch(err => {
            console.error("encountered an error:", err);
            errorState = true;
            updateStatus("PAGE.FEEDBACK.FEEDBACKNOTSAVED", errorState);
        });

        return errorState;
    }
}

export default FeedbackService;