import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Privacy extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate() {
        this.setTitle();
    }

    setTitle() {
        const { t } = this.props;
        document.title = t('PAGE.PRIVACY.DOCUMENTTITLE');
    }

    render() {
        const { t } = this.props;

        return (
            <div id="privacy">
                <div className="col-md-12">
                    <Trans i18nKey='PAGE.PRIVACY.TITLE'>{t('PAGE.PRIVACY.TITLE')}</Trans>
                    <Trans i18nKey='PAGE.PRIVACY.DISCLAIMER'>{t('PAGE.PRIVACY.DISCLAIMER')}</Trans>
                    <a href={t('PAGE.PRIVACY.LINK')} target="_blank">
                        {t('PAGE.PRIVACY.LINK')}
                        <FontAwesomeIcon className="icon" icon="external-link-alt" />
                    </a>
                </div>
            </div >
        );
    }
}

export default withTranslation()(Privacy);
