export const Eu27Data = "Data: EU-27";
export const NlData = "Data: NL";
export const Eu27DataCookieValue = "eu";
export const NlDataCookieValue = "nl";
export const LanguageCookie = "preferredLanguage";
export const ImportExportDataCookie = "import_export";
export const LanguageNlCookieValue = "nl-NL";
export const LanguageEngCookieValue = "en-GB";
export const LanguageNlSetting = "nl";
export const LanguageEngSetting = "en";
export const DataYear = 2022;
export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}\.[0-9]{1, 3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const UsernameRegex = /^[A-Za-z0-9][A-Za-z0-9_.]*[A-Za-z0-9]$/;
