import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { LanguageCookie, LanguageNlCookieValue } from '../../constants/Constants';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Show Accessibility-text
class Accessibility extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.setTitle();
    }

    componentDidUpdate() {
        this.setTitle();
    }

    setTitle() {
        const { t } = this.props;
        document.title = t('PAGE.ACCESSIBILITY.TITLE');
    }

    ShowLawTextLink() {
        const { t } = this.props;

        return (
            <>
                {t('PAGE.ACCESSIBILITY.INTROPREURL')}
                <a href="https://wetten.overheid.nl/BWBR0040936/">
                    {t('PAGE.ACCESSIBILITY.INTROURLTEXT')}<FontAwesomeIcon className="icon" icon="external-link-alt" />
                </a>
            </>
        );

    }

    render() {
        const { t } = this.props;

        return (
            <div id="accessibilityPage">
                <h1>
                    {t('PAGE.ACCESSIBILITY.TITLE')}
                </h1>
                <p>
                    <b>{t('PAGE.ACCESSIBILITY.SUMMARY')}</b>
                </p>
                <h1>
                    {t('PAGE.ACCESSIBILITY.INTROHEADER')}
                </h1>
                <p>
                    {t('PAGE.ACCESSIBILITY.INTROBODY1')}&nbsp;
                    {this.ShowLawTextLink()}.&nbsp;
                    {t('PAGE.ACCESSIBILITY.INTROBODY2')}
                </p>
                <h1>
                    {t('PAGE.ACCESSIBILITY.STATEMENTHEADER')}
                </h1>
                <p>
                    <a href="https://www.toegankelijkheidsverklaring.nl/register/1720">
                        <img src="https://www.toegankelijkheidsverklaring.nl/files/verklaring/label/28a230ef9e1cf11b083af274fbe1070c.1720.svg"
                            alt={t('PAGE.ACCESSIBILITY.STATEMENTIMGALT')} />
                    </a>
                </p>
                <h1>
                    {t('PAGE.ACCESSIBILITY.ACTIONSHEADER')}
                </h1>
                <p>
                    {t('PAGE.ACCESSIBILITY.ACTIONSBODY')}
                    <ul>
                        <li>{t('PAGE.ACCESSIBILITY.ACTIONSLIST1')}</li>
                        <li>{t('PAGE.ACCESSIBILITY.ACTIONSLIST2')}</li>
                        <li>{t('PAGE.ACCESSIBILITY.ACTIONSLIST3')}</li>
                    </ul>
                </p>
                <h1>
                    {t('PAGE.ACCESSIBILITY.CONTACTHEADER')}
                </h1>
                <p>
                    {t('PAGE.ACCESSIBILITY.CONTACTBODY')}&nbsp;
                    <a href="MAILTO:toegankelijkheid@rvo.nl">
                        toegankelijkheid@rvo.nl<FontAwesomeIcon className="icon" icon="envelope" />
                    </a>.
                </p>
            </div>
        );
    }
}

export default withTranslation()(Accessibility);
