import { getCookieValue } from '../../core/generic/Utilities';
import AuthenticationService from '../../services/AuthenticationService';

var api = new AuthenticationService();

/** Check session storage for
 *  current data. If not good,
 *  remove that data.
 * */
export function getCurrentStorageInfo() {
    if (localStorage.getItem("user") && localStorage.getItem("user").length > 0
        && localStorage.getItem("user_expiration") && localStorage.getItem("user_expiration").length > 0) {
        const expiration = new Date(localStorage.getItem("user_expiration"));
        if (expiration >= new Date()) {
            return `{"userName":"${localStorage.getItem("user")}"}`
        }
    }
    
    api.logout(_ => { });
    return "";
}

/**
 * if usercontext has a value
 * then the user is logged in
 * @param {any} userContextValue
 */
export function isUserLoggedIn(userContextValue) {
    if (userContextValue) {
        return true;
    }

    return false;
}