import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from '../core/layout/NavMenu';
import Footer from '../core/layout/Footer';
import Banner from '../core/layout/Banner';
import Breadcrumbs from '../core/generic/Breadcrumbs';

class Layout extends Component {

    render() {
        return (
            <>
                <NavMenu />
                <div className="JustifiedContent">
                    <Banner />
                    <Breadcrumbs />
                    <Container id="page-content">
                        {this.props.children}
                    </Container>
                </div>
                <Footer />
            </>
        );
    }
}

export default Layout;