import i18n from 'i18next';
import Cookies from 'js-cookie';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LanguageCookie, LanguageEngCookieValue, LanguageEngSetting, LanguageNlCookieValue, LanguageNlSetting } from './constants/Constants';
import enTranslations from './translations/en/translation';
import nlTranslations from './translations/nl/translation';
import enRoutes from './translations/en/routes';
import nlRoutes from './translations/nl/routes';

// read default language from cookie
function getDefaultLanguage() {
    const lang = Cookies.get(LanguageCookie);

    if (lang) {
        if (lang === LanguageNlCookieValue) {
            return LanguageNlSetting;
        } else if (lang === LanguageEngCookieValue) {
            return LanguageEngSetting;
        }
    }

    if (navigator.language?.toLowerCase()?.startsWith("nl")) {
        Cookies.set(LanguageCookie, LanguageNlCookieValue, { secure: true });
        return LanguageNlSetting;
    }
    Cookies.set(LanguageCookie, LanguageEngCookieValue, { secure: true });
    return LanguageEngSetting;
}

// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
    en: {
        routes: enRoutes,
        translation: enTranslations
    },
    nl: {
        routes: nlRoutes,
        translation: nlTranslations
    }
}
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(initReactI18next)
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        supportedLngs: ['nl', 'en'],
        nonExplicitSupportedLngs: true,
        ns: ['translation', 'routes'],
        defaultNS: 'translation',
        resources,
        preload: [LanguageNlSetting, LanguageEngSetting],
        debug: false,
        fallbackLng: getDefaultLanguage(),
        detection: {
            order: ['path', 'cookie', 'navigator'],
            lookupFromPathIndex: 0,
            checkWhitelist: true,
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
            wait: true,
            transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'h1', 'h2', 'h3', 'h4', 'ul', 'li', 'ol', 'div']
        }
});

export default i18n;