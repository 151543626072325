import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Row, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import FeedbackService from "../../services/FeedbackService";
import { UserContext } from "../../context/UserContext";
import FeedbackButton from "../../images/feedback-button.png";
import StarRatingComponent from "react-star-rating-component";
import AuthenticationModal from '../authentication/AuthenticationModal';
const api = new FeedbackService();

class Feedback extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            errorState: false,
            success: false,
            resMsg: "",
            rating: 5,
            isLoading: false,
            feedbackText: ""
        };

        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({ modal: !this.state.modal });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.name === "isGoing" ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    getFeedbackButton() {
        return (
            <div id="btn-feedback" onClick={this.toggle.bind(this)}>
                <img src={FeedbackButton} alt="Give feedback"/>
            </div>
        );
    }

    setResponse(msg, state) {
        this.setState({ resMsg: msg, errorState: state });
    }

    showMessageAlert() {
        const { t } = this.props;

        if (this.state.resMsg) {
            let alertColor;
            if (this.state.errorState) {
                alertColor = "danger";
            } else {
                alertColor = "success";
            }

            return (
                <Alert color={alertColor}>
                    {t(this.state.resMsg)}
                </Alert>
            );
        }

        return null;
    }

    resetComponent() {
        this.setState({ resMsg: null, errorState: false, rating: 5, success: false, isLoading: false });
    }

    async sendFeedback() {
        this.setState({ isLoading: true });

        // send feedback to db
        const hasError = await api.saveFeedback(
           // token,
            this.state.feedbackText,
            this.state.rating,
            window.location.href,
          //  userName,
            this.setResponse.bind(this));

        this.setState({ isLoading: false, success: !hasError });
    }

    onStarClick(nextValue, prevValue, name) {
        // nextValue contains the new value received
        if (!this.state.isLoading && !this.state.success) {
            this.setState({ rating: nextValue });
        }
    }

    render() {
        const { t } = this.props;
        const starMax = 10;

        return (
            <div>
                {this.getFeedbackButton()}
                {!this.context.value && this.state.modal && <AuthenticationModal toggle={this.toggle} text={t("PAGE.FEEDBACK.LOGINMESSAGE")} />}

                {!!this.context.value && this.state.modal && <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                    onClosed={this.resetComponent.bind(this)}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        <div className="form-group">
                            <label className="feedback-header-text">{t("PAGE.FEEDBACK.RATING")}</label>
                            <div id="rating-container">
                                <div className="star-rating-container">
                                    <StarRatingComponent
                                        name="feedbackrating"
                                        starCount={starMax}
                                        value={this.state.rating}
                                        onStarClick={this.onStarClick.bind(this)}
                                        emptyStarColor="#a9a9a9" /> {/* dark-grayish-red */}
                                </div>
                                {this.state.rating + " " + t("PAGE.FEEDBACK.OUTOF") + " " + starMax}
                            </div>
                            <div className="form-group">
                                <label className="feedback-header-text">{t("PAGE.FEEDBACK.TITLE")}</label>
                                <textarea
                                    disabled={this.state.isLoading || this.state.success}
                                    rows="8"
                                    cols="30"
                                    className="form-control"
                                    id="feedback"
                                    name="feedbackText"
                                    onChange={this.handleInputChange}
                                    placeholder={t("PAGE.FEEDBACK.PLACEHOLDER")}
                                    required>
                                </textarea>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {this.showMessageAlert()}
                        {this.state.success
                            ? null
                            : <button
                                disabled={this.state.isLoading || this.state.success}
                                className="btn btn-primary btn-wide float-left"
                                onClick={this.sendFeedback.bind(this)}>

                                <span>{t("PAGE.GLOBAL.SEND")}</span>
                            </button>
                        }
                    </ModalFooter>
                </Modal>}
            </div>
        );
    }
}

export default withTranslation()(Feedback);