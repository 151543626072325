import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import AuthenticationService from '../../services/AuthenticationService';
import FavoriteService from '../../services/FavoriteService';
import { UserContext } from '../../context/UserContext';
import ForgotModal from "./ForgotModal";
import ReactGA from "react-ga4";

const api = new AuthenticationService();
const favApi = new FavoriteService();

// Login part of AuthenticationModal.js
class Login extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            rememberMe: false,
            forgotUsername: false,
            forgotPassword: false
        }
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleCheckboxInputChange = (event) => {
        const { name, checked } = event.target;

        this.setState({
            [name]: checked
        });
    }

    login = async (e) => {
        e.preventDefault();
        this.btn.setAttribute("disabled", "disabled");

        const loginSuccess = await api.login(this.state.username,
            this.state.password,
            this.context.setValue,
            this.props.callback);

        if (loginSuccess) {
            ReactGA.event({
                category: "User",
                action: "User Logged in",
                nonInteraction: false,
            });
            this.props.onSuccess();
            await this.getUserFavorites();
        }

        this.btn.removeAttribute("disabled");
    }

    // after logging in, check whether user has any favourites for the navbar
    async getUserFavorites() {
        const jsonInfo = JSON.parse(this.context.value);
        const { token } = jsonInfo;

        const userHasFavorites = await favApi.getFavoritesInfo(token);

        this.props.favoritecb(userHasFavorites);
    }

    showForgotUsername() {
        this.setState({ forgotUsername: true });
    }

    hideForgotUsername() {
        this.setState({ forgotUsername: false });
    }

    showForgotPassword() {
        this.setState({ forgotPassword: true });
    }

    hideForgotPassword() {
        this.setState({ forgotPassword: false });
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Row>
                    <Col>
                        <h2>{t('PAGE.LOGIN.RETURNUSER')}</h2>
                        <form name="loginForm" onSubmit={this.login}>
                            <fieldset>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="username"
                                        className="form-control"
                                        placeholder={t('PAGE.LOGIN.USERNAME')}
                                        autoFocus
                                        tabIndex="1"
                                        onChange={this.handleInputChange}
                                        required />
                                    {
                                        this.state.forgotUsername &&
                                        <ForgotModal forgotUsername={true} callback={this.hideForgotUsername.bind(this)} />
                                    }
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        className="form-control"
                                        placeholder={t('PAGE.LOGIN.PASSWORD')}
                                        tabIndex="2"
                                        onChange={this.handleInputChange}
                                        required />
                                    {this.state.forgotPassword
                                        ? <ForgotModal forgotUsername={false} callback={this.hideForgotPassword.bind(this)} />
                                        : null}
                                </div>
                                <button
                                    ref={btn => { this.btn = btn; }}
                                    type="submit"
                                    className="btn btn-primary btn-wide-modal float-left w-75"
                                    tabIndex="4">
                                    <span>{t('PAGE.GLOBAL.LOGIN')}</span>
                                </button>
                            </fieldset>
                        </form>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col>
                        <span className="userdialogtext-clickable" onClick={this.showForgotPassword.bind(this)} tabIndex="6">
                            {t('PAGE.LOGIN.PASSWORD')} {t('PAGE.LOGIN.FORGOT')}
                        </span>
                    </Col>
                    <Col>
                        <span className="userdialogtext-clickable" onClick={this.showForgotUsername.bind(this)} tabIndex="5">
                            {t('PAGE.LOGIN.USERNAME')} {t('PAGE.LOGIN.FORGOT')}
                        </span>
                    </Col>
                </Row>

            </>
        );
    }
}

export default withTranslation()(Login);