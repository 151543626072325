import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import AuthenticationService from '../../services/AuthenticationService';
import { UserContext } from '../../context/UserContext';
import PasswordStrength from './PasswordStrength';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import Disclaimer from '../generic/Disclaimer';
import { EmailRegex, UsernameRegex } from '../../constants/Constants';

const api = new AuthenticationService();

// Register part of AuthenticationModal.js
class Register extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            usernameValid: false,
            email: '',
            emailValid: false,
            password: '',
            passwordValid: false,
            passwordConfirm: '',
            passwordConfirmValid: false,
            conditionBox: false,
            formValid: false,
            errorMsg: {},
            modal: false
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    handleCheckboxInputChange = (event) => {
        const { name, checked } = event.target;

        this.setState({
            [name]: checked
        });
    }

    validateForm = () => {
        const { usernameValid, emailValid, passwordValid, passwordConfirmValid, conditionBox } = this.state;

        let newFormState = usernameValid && emailValid && passwordValid && passwordConfirmValid && conditionBox;

        if (this.state.formValid != newFormState) {
            this.setState({
                formValid: newFormState
            });
        }
    }

    handlePasswordInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const name = target.name;

        const { passwordConfirm } = this.state;
        let passwordConfirmStillValid = false;
        if (passwordConfirm == value) {
            passwordConfirmStillValid = true;
        }

        this.setState({ [name]: value, passwordConfirmValid: passwordConfirmStillValid }, this.validateForm);
    }

    validatePassword(valid) {
        this.setState({ passwordValid: valid });
    }

    validatePasswordConfirmInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;

        let valid = false;

        if (this.state.password === value) {
            valid = true;
        }

        this.setState({
            passwordConfirm: value,
            passwordConfirmValid: valid
        },
            this.validateForm);
    }

    validateEmailInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const valid = EmailRegex.test(value);

        this.setState({
            email: value,
            emailValid: valid
        },
            this.validateForm);
    }

    validateUsernameInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const valid = UsernameRegex.test(value) && value.length > 0;

        this.setState({
            username: value,
            usernameValid: valid
        },
            this.validateForm);
    }

    controlExtraCss(value, valueValid) {
        let className = '';

        if (value) {
            if (valueValid) {
                className = "input-correct";
            } else {
                className = "input-error";
            }
        }

        return className;
    }

    checkboxExtraCss() {
        let className = '';

        if (this.state.conditionBox) {
            className = '';
        } else if (!this.state.formValid &&
            (this.state.email || this.state.username || this.state.password || this.state.passwordConfirm)) {
            className = "input-error";
        }

        return className;
    }

    register = async (e) => {
        e.preventDefault();

        this.btn.setAttribute("disabled", "disabled");

        await api.register(
            this.state.email,
            this.state.username,
            this.state.password,
            this.state.passwordConfirm,
            this.props.callback
        );

        if (this.btn) {
            this.btn.removeAttribute("disabled");
        }
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <Row>
                    <Col>
                        <h2>{t('PAGE.LOGIN.NEWUSER')}</h2>
                        <form name="registerForm" onSubmit={this.register}>
                            <fieldset>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        className={"form-control " +
                                            this.controlExtraCss(this.state.email, this.state.emailValid)}
                                        placeholder={t('PAGE.LOGIN.EMAILADDRESS')}
                                        tabIndex="7"
                                        onChange={this.validateEmailInput}
                                        required />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="username"
                                        className={"form-control " +
                                            this.controlExtraCss(this.state.username, this.state.usernameValid)}
                                        placeholder={t('PAGE.LOGIN.USERNAME')}
                                        tabIndex="8"
                                        onChange={this.validateUsernameInput}
                                        pattern="^[A-Za-z0-9][A-Za-z0-9_.]*[A-Za-z0-9]$"
                                        required />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        name="password"
                                        className={"form-control " +
                                            this.controlExtraCss(this.state.password, this.state.passwordValid)}
                                        placeholder={t('PAGE.LOGIN.PASSWORD')}
                                        tabIndex="9"
                                        onChange={this.handlePasswordInput}
                                        required />
                                    {this.state.password
                                        ? <PasswordStrength
                                            pass={this.state.password}
                                            validation={this.validatePassword.bind(this)}
                                            valid={this.state.passwordValid} />
                                        : null}
                                    <div className="password-hint">{t('PAGE.LOGIN.PASSWORDREQUIREMENT')}</div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="password"
                                        className={"form-control " +
                                            this.controlExtraCss(this.state.passwordConfirm, this.state.passwordConfirmValid)}
                                        name="passwordConfirm"
                                        placeholder={t('PAGE.LOGIN.CONFIRMPASSWORD')}
                                        tabIndex="10"
                                        onChange={this.validatePasswordConfirmInput}
                                        required />
                                </div>
                                <div className="form-group checkbox-container with-margin">
                                    <input
                                        type="checkbox"
                                        name="conditionBox"
                                        checked={this.state.conditionBox}
                                        onChange={this.handleCheckboxInputChange}
                                        required />
                                    <label className={this.checkboxExtraCss()}
                                        onClick={() => this.setState({ conditionBox: !this.state.conditionBox },
                                            this.validateForm)}>
                                    </label>

                                    <span className="accept-terms-conditions">{t('PAGE.LOGIN.ACCEPTCONDITIONS')}</span>
                                    <span onClick={this.toggle} className="terms-conditions">
                                        {t('PAGE.LOGIN.LINKCONDITIONS')}
                                    </span>
                                    <Modal isOpen={this.state.modal} toggle={this.toggle}>
                                        <ModalHeader toggle={this.toggle}></ModalHeader>
                                        <ModalBody>
                                            <Disclaimer />
                                        </ModalBody>
                                    </Modal>

                                </div>
                                <button
                                    ref={btn => { this.btn = btn; }}
                                    disabled={!this.state.formValid}
                                    type="submit"
                                    className="btn btn-primary btn-wide-modal float-left w-75"
                                    id="RegisterButton">
                                    <span> {t('PAGE.LOGIN.REGISTER')}</span>
                                </button>
                            </fieldset>
                        </form>
                    </Col>
                </Row>
                <hr />
            </>
        );
    }
}

export default withTranslation()(Register);