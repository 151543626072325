/** Function for translating Breadcrumbs */
export function NavHeaders() {
    var headers = {};

    headers['info'] = "PAGE.INFO.HEADER";
    headers['fav'] = "PAGE.FAVORITES.HEADER";
    headers['uranium'] = "PAGE.BIODIVERSITY.URANIUMHEADER";

    // EN
    headers['explore'] = "PAGE.PRODUCTGROUPEXPLORE.HEADER";
    headers['assess'] = "PAGE.PRODUCTGROUPCRITICIZE.HEADER";
    headers['act'] = "PAGE.ACTIONPERSPECTIVE.HEADER";
    headers['resource'] = "PAGE.RAWMATERIALFACTSHEET.HEADER";
    headers['productgroup'] = "PAGE.PRODUCTGROUPFACTSHEET.HEADER";
    headers['confirm'] = "PAGE.CONFIRM.HEADER";
    headers['biodiversity'] = "PAGE.BIODIVERSITY.HEADER";
    headers['copper'] = "PAGE.BIODIVERSITY.COPPERHEADER";
    headers['gold'] = "PAGE.BIODIVERSITY.GOLDHEADER";
    headers['cotton'] = "PAGE.BIODIVERSITY.COTTONHEADER";
    headers['rice'] = "PAGE.BIODIVERSITY.RICEHEADER";

    // NL
    headers['verkennen'] = "PAGE.PRODUCTGROUPEXPLORE.HEADER";
    headers['beoordelen'] = "PAGE.PRODUCTGROUPCRITICIZE.HEADER";
    headers['handelen'] = "PAGE.ACTIONPERSPECTIVE.HEADER";
    headers['grondstof'] = "PAGE.RAWMATERIALFACTSHEET.HEADER";
    headers['productgroep'] = "PAGE.PRODUCTGROUPFACTSHEET.HEADER";
    headers['bevestigen'] = "PAGE.CONFIRM.HEADER";
    headers['biodiversiteit'] = "PAGE.BIODIVERSITY.HEADER";
    headers['koper'] = "PAGE.BIODIVERSITY.COPPERHEADER";
    headers['goud'] = "PAGE.BIODIVERSITY.GOLDHEADER";
    headers['katoen'] = "PAGE.BIODIVERSITY.COTTONHEADER";
    headers['rijst'] = "PAGE.BIODIVERSITY.RICEHEADER";

    return headers;
}