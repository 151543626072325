import React, { Component } from 'react';
import Api from './Api';

class AccountService extends Component {
    constructor(props) {
        super(props);
    }

    // confirm user email
    async confirmMail(code, func) {
        var errorState = false;
        const urlPost = '/api/account/Confirm/' + code;

        await Api.post(urlPost).then(res => {
            // do nothing
        }).catch(err => {
            console.error('encountered an error:', err);

            // get error message to show on screen from response
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.message) {
                        func(err.response.data.message);
                    }
                }
            }

            errorState = true;
        });

        return errorState;
    }

    // reset user password
    async resetPassword(username, password, confirmpassword, token, func) {
        const payload = "Username=" +
            encodeURIComponent(username) +
            "&Password=" +
            encodeURIComponent(password) +
            "&ConfirmPassword=" +
            encodeURIComponent(confirmpassword) +
            "&token=" +
            encodeURIComponent(token);

        const header = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };

        var errorState = false;

        await Api.post('/api/account/ResetPassword', payload, header).then(res => {
            // do nothing
        }).catch(err => {
            console.error('encountered an error:', err);
            errorState = true;

            // check whether error message in response
            // and save this to the given function
            if (err.response) {
                if (err.response.data) {
                    if (err.response.data.modelState) {
                        if (err.response.data.modelState[""]) {
                            if (err.response.data.modelState[""].length > 0) {
                                func(err.response.data.modelState[""][0]);
                            }
                        }
                    }
                }
            }
        });

        return errorState;
    }
}

export default AccountService;