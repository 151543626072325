import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap';
import AuthenticationService from '../../services/AuthenticationService';
import { EmailRegex } from '../../constants/Constants';

const api = new AuthenticationService();

class ForgotModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: true,
            username: '',
            usernameValid: false,
            email: '',
            emailValid: false,
            emptyModal: false,
            resMsg: '',
            errorState: false
        }
    }

    toggle = () => {
        this.setState({ modal: !this.state.modal });
    }

    // VALIDATE INPUT
    validateEmailInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;
        const valid = EmailRegex.test(value);

        this.setState({
            email: value,
            emailValid: valid
        });
    }

    validateUsernameInput = (event) => {
        const target = event.target;
        const value = target.name === 'isGoing' ? target.checked : target.value;

        var valid = false;

        if (/^[ A-Za-z0-9_@.\/#&+-]*$/.test(value) && value.length > 0) {
            valid = true;
        }

        this.setState({
            username: value,
            usernameValid: valid
        });
    }

    // USERNAME
    sendUsernameAction() {
        if (this.state.emailValid) {
            // do post
            this.btn.setAttribute("disabled", "disabled");
            api.forgotuser(this.state.email, this.setResponse.bind(this));
            this.btn.removeAttribute("disabled");
        }
    }

    getUserNameModalBody() {
        const { t } = this.props;

        return (
            <>
                <p>{t('PAGE.LOGIN.GETUSERNAMEINTRO')}</p>
                <div className="form-group">
                    <input
                        type="text"
                        name="email"
                        className={"form-control " + this.controlExtraCss(this.state.email, this.state.emailValid)}
                        placeholder={t('PAGE.LOGIN.EMAILADDRESS')}
                        onChange={this.validateEmailInput}
                        required
                        autoFocus />

                    {this.showAlert()}
                </div>

                <div className="row modal-footer">
                    <button
                        ref={btn => { this.btn = btn; }}
                        onClick={this.sendUsernameAction.bind(this)}
                        className="btn btn-primary btn-wide pull-left">
                        <span>{t('PAGE.GLOBAL.SEND')}</span>
                    </button>

                </div>
            </>
        );
    }

    // PASSWORD
    sendPasswordAction() {
        if (this.state.usernameValid) {
            // do post
            this.btn.setAttribute("disabled", "disabled");
            api.forgotpass(this.state.username, this.setResponse.bind(this));
            this.btn.removeAttribute("disabled");
        }
    }

    getPasswordModalBody() {
        const { t } = this.props;

        return (
            <>
                <p>{t('PAGE.LOGIN.RESETPASSWORDINTRO')}</p>
                <div className="form-group">
                    <input
                        type="text"
                        name="username"
                        className={"form-control " +
                            this.controlExtraCss(this.state.username, this.state.usernameValid)}
                        placeholder={t('PAGE.LOGIN.USERNAME')}
                        onChange={this.validateUsernameInput}
                        required
                        autoFocus />

                    {this.showAlert()}
                </div>

                <div className="row modal-footer">
                    <button
                        ref={btn => { this.btn = btn; }}
                        onClick={this.sendPasswordAction.bind(this)}
                        className="btn btn-primary btn-wide pull-left">
                        <span>{t('PAGE.GLOBAL.SEND')}</span>
                    </button>

                </div>
            </>
        );
    }

    // OTHER GENERIC FUNCTIONS
    controlExtraCss(value, valueValid) {
        var className = '';

        if (value) {
            if (valueValid) {
                className = "input-correct";
            } else {
                className = "input-error";
            }
        }

        return className;
    }

    showAlert() {
        const { t } = this.props;

        if (this.state.resMsg) {
            var alertColor = 'info';
            if (this.state.errorState) {
                alertColor = 'danger';
            } else {
                alertColor = 'success';
            }

            return (
                <Alert color={alertColor}>
                    {t(this.state.resMsg)}
                </Alert>
            );
        }

        return null;
    }

    setResponse(msg, state) {
        this.setState({ resMsg: msg, errorState: state });
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className={this.props.className}
                    onClosed={this.props.callback}>
                    <ModalHeader toggle={this.toggle}></ModalHeader>
                    <ModalBody>
                        {this.props.forgotUsername ? this.getUserNameModalBody() : this.getPasswordModalBody()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default withTranslation()(ForgotModal);