import React, { useState, Suspense, lazy } from "react";
import { useTranslation } from 'react-i18next';
import { Route, Switch } from "react-router";
import { Redirect } from 'react-router-dom';
import { library } from "@fortawesome/fontawesome-svg-core";
import { Spinner } from "reactstrap";
import Layout from "./containers/Layout";
import Home from "./containers/Home/Home";
import Accessibility from "./containers/Accessibility/Accessibility";
import Cookie from "./containers/Cookie/Cookie";
import Privacy from "./containers/Privacy/Privacy";
import Confirm from "./containers/Confirm/Confirm";
import ResetPassword from "./containers/ResetPassword/ResetPassword";
import { UserContext } from "./context/UserContext";
import { FavoriteContext } from "./context/FavoriteContext";
import PrivateRoute from "./core/route/PrivateRoute";
import { getCurrentStorageInfo, isUserLoggedIn } from "./core/authentication/AuthInfo";
import { getSessionFavInfo } from "./containers/Favorites/FavInfo";
import withTracking from "./core/tracking/withTracking";;
import ReactGA from "react-ga4";

const DEFAULT_CONFIG = {
    trackingId: "",
    gaOptions: {
        cookieDomain: "none",
        anonymizeIp: true,
        testMode: false,
    },
};

// lazy loading of containers
const Action = lazy(() => import("./containers/Action/Action"));
const Assess = lazy(() => import("./containers/Assess/Assess"));
const Biodiversity = lazy(() => import("./containers/Biodiversity/Biodiversity"));
const Contact = lazy(() => import("./containers/Contact/Contact"));
const CopperPage = lazy(() => import("./containers/Biodiversity/CopperPage"));
const CottonPage = lazy(() => import("./containers/Biodiversity/CottonPage"));
const CountryFactsheet = lazy(() => import("./containers/CountryFactsheet/CountryFactsheet"));
const EnvironmentalImpact = lazy(() => import("./containers/EnvironmentalImpact/EnvironmentalImpact"));
const Error404 = lazy(() => import("./core/generic/Error404"));
const Explore = lazy(() => import("./containers/Explore/Explore"));
const Favorites = lazy(() => import("./containers/Favorites/Favorites"));
const FAQ = lazy(() => import("./containers/FAQ/FAQ"));
const GoldPage = lazy(() => import("./containers/Biodiversity/GoldPage"));
const Health = lazy(() => import("./containers/Health/Health"));
const Information = lazy(() => import("./containers/Information/Information"));
const ProductgroupFactsheet = lazy(() => import("./containers/ProductgroupFactsheet/ProductgroupFactsheet"));
const ResourceFactsheet = lazy(() => import("./containers/ResourceFactsheet/ResourceFactsheet"));
const RicePage = lazy(() => import("./containers/Biodiversity/RicePage"));
const UraniumPage = lazy(() => import("./containers/Biodiversity/UraniumPage"));

// NOTE: Update NavHeaders.js when adding a new route.

function App() {
    DEFAULT_CONFIG.gaOptions.cookieDomain = window.location.hostname;
    if (window.location.hostname.includes("localhost")) {
        DEFAULT_CONFIG.gaOptions.testMode = true;
        DEFAULT_CONFIG.trackingId = "G-5FGHPYQK59";
    } else if (window.location.hostname.includes("acc.")) {
        DEFAULT_CONFIG.trackingId = "G-5FGHPYQK59";
    } else {
        DEFAULT_CONFIG.trackingId = "G-TEP4BY9Q90";
    }

    const [reactGaInitialised, setReactGaInitialised] = useState(false);

    const initReactGA = () => {
        if (!reactGaInitialised) {
            ReactGA.initialize(DEFAULT_CONFIG.trackingId, DEFAULT_CONFIG);
            setReactGaInitialised(true);
        }
    };
    const [value, setValue] = useState(getCurrentStorageInfo());
    const [favValue, setFavValue] = useState(getSessionFavInfo());

    const isAuthenticated = isUserLoggedIn(value);

    initReactGA();
    if (isAuthenticated) {
        ReactGA.event({
            category: "User",
            action: "Returning Logged In User",
            nonInteraction: true,
        });
    }

    const api_regex = /^\/api\/.*/
    // If using "/api/" in the pathname, skip React Router
    if (api_regex.test(window.location.pathname)) {
        return `API endpoint not defined: ${window.location.pathname}`;
    }

    const { t } = useTranslation();

    return (
        <UserContext.Provider value={{ value, setValue }}>
            <FavoriteContext.Provider value={{ favValue, setFavValue }}>
                <Layout>
                    <Suspense fallback={<div className="text-center" style={{ width: "100%" }}>
                        <Spinner color="primary" /></div>}>
                        <Switch>
                            <Redirect exact from="/" to={t('routes:home')} />

                            <Route exact path={`/:locale(en|nl)?${t('routes:home')}`} component={withTracking(Home)} />

                            <Route exact path={`/:locale(en|nl)?${t('routes:accessibility')}`} component={withTracking(Accessibility)} />

                            <Route exact path={`/:locale(en|nl)?${t('routes:cookie')}`} component={withTracking(Cookie)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:contact')}`} component={withTracking(Contact)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:privacy')}`} component={withTracking(Privacy)} />

                            <Route exact path={`/:locale(en|nl)?${t('routes:confirm')}/:confirmationid`} component={Confirm} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:newpass')}`} component={ResetPassword} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:info')}`} component={withTracking(Information)} />
                            <PrivateRoute exact path={`/:locale(en|nl)?${t('routes:fav')}`} component={withTracking(Favorites)} auth={isAuthenticated} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:faq')}`} component={withTracking(FAQ)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:explore')}`} component={withTracking(Explore)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:assess')}`} component={withTracking(Assess)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:act')}`} component={withTracking(Action)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:resource')}/:resourceid`} component={withTracking(ResourceFactsheet)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:productgroup')}/:groupid`} component={withTracking(ProductgroupFactsheet)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:country')}`} component={withTracking(CountryFactsheet)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:country')}/:countryid`} component={withTracking(CountryFactsheet)} />

                            <Route exact path={`/:locale(en|nl)?${t('routes:biodiversity')}`} component={withTracking(Biodiversity)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:copper')}`} component={withTracking(CopperPage)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:gold')}`} component={withTracking(GoldPage)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:uranium')}`} component={withTracking(UraniumPage)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:cotton')}`} component={withTracking(CottonPage)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:rice')}`} component={withTracking(RicePage)} />
                            <Route exact path={`/:locale(en|nl)?${t('routes:environment')}/:resourceid`} component={withTracking(EnvironmentalImpact)} />

                            <Route exact path="/health" component={withTracking(Health)} />

                            <Route component={withTracking(Error404)} />
                        </Switch>
                    </Suspense>
                </Layout>
            </FavoriteContext.Provider>
        </UserContext.Provider>
    );
}

export default App;

// icon import
import {
    faUser,
    faStar,
    faEnvelope,
    faTimes,
    faSort,
    faSortUp,
    faSortDown,
    faEuroSign,
    faClock,
    faCog,
    faCaretUp,
    faCaretDown,
    faPrint,
    faDownload,
    faRecycle,
    faExternalLinkAlt,
    faDiceOne,
    faDiceTwo,
    faDiceThree,
    faDiceFour,
    faCaretRight
} from "@fortawesome/free-solid-svg-icons";

// add icons to library
library.add(
    faUser,
    faStar,
    faEnvelope,
    faTimes,
    faSort,
    faSortUp,
    faSortDown,
    faEuroSign,
    faClock,
    faCog,
    faCaretUp,
    faCaretDown,
    faPrint,
    faDownload,
    faRecycle,
    faExternalLinkAlt,
    faDiceOne,
    faDiceTwo,
    faDiceThree,
    faDiceFour,
    faCaretRight
);
